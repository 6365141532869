import React from "react";
import TrainingService from "../sections/standardPage/TrainingService";
import { graphql } from 'gatsby'

const TrainingPage = (data) =>
  <TrainingService data={data}/>

export default TrainingPage;

export const query = graphql`
  {
    contentfulStandardPage(pageId: {eq: 1}) {
      heading
      subheading
      pageName
      pageId
      bodyText {
        json
      }
      twoColumns
      secondColumnBodyText {
        json
      }
      pageImage {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }`
